.updateBannerContainer {
  position: absolute;
  display: flex;
  top: 14px;
  width: 100%;
}

.updateBanner {
  z-index: 29;
  display: flex;
  flex-grow: 0;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px;
  grid-gap: 20px;
  margin-left: auto;
  margin-right: auto;
}

.updateButton {
  border: 0;
  background-color: #11B14B;
  border-radius: 2px;
  font-weight: 400;
  font-size: 14px;
}

.updateButton:hover {
  background-color: #108000;
}

.updateButton:active {
  background-color: #108000 !important;
}
