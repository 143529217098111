header {
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #2f3033;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  box-sizing: border-box;
  z-index: 19;
  position: relative;
}

.logo {
  font-size: 20px;
  color: #fff;
  text-decoration: none;
}

.logo:hover {
  color: #fff;
  text-decoration: none;
}

/* .userMenuBtn {
  position: relative;
  background-color: #2f3033;
  border: none;
  border: 1px solid #fff;
  border-radius: 50%;
  color: #f7f8fb;
  padding: 3px;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.userMenuBtn::before {
  content: "";
  position: absolute;
  left: -12px;
  top: -12px;
  display: block;
  width: 1px;
  height: 80px;
  background-color: rgba(255, 255, 255, 0.2);
}

.userMenuBtn svg {
  width: 28px;
  height: 36px;
} */

/* Account menu */

/* .acoountMenuWrapper {
  position: absolute;
  min-width: 180px;
  top: 90%;
  right: 40px;
  padding: 5px 20px 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 1px 8px 16px -9px rgba(66, 68, 90, 1);
}

.userName {
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  color: #000;
  margin-bottom: 0;
}
.userEmail {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #000;
  margin-bottom: 15px;
} */

.logoutBtn {
  font-size: 18px;
  font-weight: 400;
  color: #fff !important;
  transition: color 250ms ease-in-out;
  padding: 0;
  cursor: pointer;
}

.logoutBtn:hover {
  color: lightslategray !important;
  box-shadow: none;
}
