body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

#app {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  cursor: pointer;
}

/*

  font: inherit;
  background: #ffffff;
  padding: 0.5rem 2rem;
  color: #000000;
  border: 1px solid #8D9096;
  margin: 0.5rem 0;
  border-radius: 1px;

  */

button:hover,
button:active {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
}

button:focus {
  outline: none;
}

.location-content {
  padding: 20px 20px 0px 20px;
  width: 300px;
}

.react-selectize.root-node {
    width: 100% !important;
}

.react-selectize {
  display: block !important;
}

.popover {
  z-index:20;
}

.globalCursorPoint {
  cursor: pointer
}

.isolatedCursorPointer {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}
